import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import rehypeReact from "rehype-react"

export default ({ data }) => {
  const post = data.markdownRemark

  const explainerReturns = data.stackExplainers
  const allExplainers = explainerReturns.edges
  const explainerCount = allExplainers.length

  const renderAst = new rehypeReact({
    createElement: React.createElement
  }).Compiler

  return (
    <Layout>

      <div className="stack-image">
        <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
      </div>

      <div className="stack-story-caption">
        <h5>{post.frontmatter.caption}</h5>
      </div>

      <div className="stack-title">
        <h2>
          {post.frontmatter.title}
        </h2>
        <h3>
          {post.frontmatter.blurb}
        </h3>
      </div>

      <div className="stack-story-timestamp">
        <h5 className="timestamp">Updated {post.frontmatter.date}</h5>
      </div>

      <div className="stack-body">
        <div>{renderAst(post.htmlAst)}</div>
      </div>

      { (explainerCount > 0) ?
        <div className="story-story-card-attachment">
          <div class="feed-label">
            <h4>Explainers</h4>
          </div>
          {data.stackExplainers.edges.map(({node}) => (
            <div className="story-card">
              <Link to={node.fields.slug}>
                <div className="story-card-text">
                  <h3>
                    {node.frontmatter.title}
                  </h3>
                  <h5 className="timestamp">
                    {node.frontmatter.date}
                  </h5>
                  <div>
                    {node.frontmatter.blurb}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      :
        null
      }

      <div className="story-story-card-attachment">
        <div class="feed-label">
          <h4>News</h4>
        </div>
        {data.stackStories.edges.map(({node}) => (
          <div className="story-card">
            <Link to={node.fields.slug}>
              <div className="story-card-text">
                <h3>
                  {node.frontmatter.title}
                </h3>
                <h5 className="timestamp">
                  {node.frontmatter.date}
                </h5>
                <div>
                  {node.frontmatter.blurb}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>      

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $stack: [String!]) {

    markdownRemark(
      fields: { slug: { eq: $slug }}
    ) {
      frontmatter {
        title
        blurb
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        caption
        epic
        stack
        people
        date(formatString: "MMMM Do")
      }
      fields {
        slug
      }
      htmlAst
    }

    stackStories: allMarkdownRemark(
      filter: {
        frontmatter: {
          stack: {
            in: $stack
          }
          type: {
            ne: "explainer"
          }
        } 
        fields: { 
          slug: { 
            ne: $slug 
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ){
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
            blurb
            type
            epic
            people
            stack
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst   
        }
      }
    }

    stackExplainers: allMarkdownRemark(
      filter: {
        frontmatter: {
          stack: {
            in: $stack
          }
          type: {
            eq: "explainer"
          }
        } 
        fields: { 
          slug: { 
            ne: $slug 
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ){
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
            blurb
            type
            epic
            people
            stack
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst   
        }
      }
    }
  }
`